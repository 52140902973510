<template>
  <div>
    <div class="card-body">
      <!-- Header -->
      <div class="d-md-flex align-items-center">
        <div>
          <h4 class="card-title">Sales Summary</h4>
          <h5 class="card-subtitle text-muted font-weight-normal">
            Overview of Latest Month
          </h5>
        </div>
        <div class="ml-auto d-flex no-block align-items-center m-2 mt-md-0">
          <ul class="list-group list-group-horizontal font-12 mr-3 mb-0">
            <li
              class="list-group-item text-info border-0 p-0 d-flex align-items-center mr-2"
            >
              <i class="mdi mdi-checkbox-blank-circle mr-1"></i> Students
            </li>
            <li
              class="list-group-item text-primary border-0 p-0 d-flex align-items-center"
            >
              <i class="mdi mdi-checkbox-blank-circle mr-1"></i> Enrollments
            </li>
          </ul>
          <b-form-select
            :options="salesoptions1"
            v-model="sales1"
          ></b-form-select>
        </div>
      </div>
      <!-- Chart and Data Section -->
      <b-row class="justify-content-center">
        <b-col lg="4" cols="12" md="4">
          <h1 class="mb-0 mt-4">PKR{{totalincomes[0].money}}</h1>
          <h6 class="font-weight-light text-muted">Earnings</h6>
          <h3 class="mt-4 mb-0">{{totalCourses}}</h3>
          <h6 class="font-weight-light text-muted">Courses</h6>
          <b-button variant="primary" class="mt-3 p-3 pl-4 pr-4 mb-3 py-3"
            >Last Month Summary</b-button
          >
        </b-col>
        <b-col cols="12" lg="8" md="8">
          <div class="d-flex flex-column w-100">
            <vue-apex-charts
              type="area"
              height="250"
              :options="chartData.chartOptions"
              :series="chartData.series"
            ></vue-apex-charts>
          </div>
        </b-col>
      </b-row>
    </div>
    <!-- Footer -->
    <MonthlyProfitList :totalincomes="totalincomes"></MonthlyProfitList>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import apexChartData from "../../../charts/apexcharts/ApexChartsData";
import MonthlyProfitList from "../monthly-profit-list/MonthlyProfitList";
import { mapActions } from 'vuex';
import _ from "lodash";
export default {
  name: "SalesSummery",
  data: () => ({
    title: "SalesSummery",
    totalCourses: 0,
    totalEnrollments: 0,
    totalPayments: 0,
    totalPayouts: 0,
    sales1: 0,
    salesoptions1: [
      { text: "Monthly", value: 0 },
      { text: "Daily", value: 1 },
      { text: "Weekly", value: 2 },
      { text: "Yearly", value: 3 },
    ],
    apexChartData: apexChartData,
    // Data for TotalIncome
    totalincomes: [
      {
        gridlg: "col-lg-3",
        gridsm: "col-md-6",
        iconcolor: "text-warning",
        icon: "mdi mdi-wallet",
        textcolor: "text-dark",
        text: "Wallet Balance",
        money: "",
      },
      {
        gridlg: "col-lg-3",
        gridsm: "col-md-6",
        iconcolor: "text-info",
        icon: "mdi mdi-star-circle",
        textcolor: "text-dark",
        text: "Available Earnings",
        money: "",
      },
      {
        gridlg: "col-lg-3",
        gridsm: "col-md-6",
        iconcolor: "text-primary",
        icon: "mdi mdi-shopping",
        textcolor: "text-dark",
        text: "Claimed Earnings",
        money: "",
      },
      {
        gridlg: "col-lg-3",
        gridsm: "col-md-6",
        iconcolor: "text-success",
        icon: "mdi mdi-currency-usd",
        textcolor: "text-dark",
        text: "Alphazzz Earnings",
        money: "",
      },
    ],
    chartData: {
      series: [
        {
          name: "Enrollements",
          data: [0, 5, 6, 8, 25, 9, 8, 24],
        },
        {
          name: "Students",
          data: [0, 3, 1, 2, 8, 1, 5, 1],
        },
      ],

      chartOptions: {
        grid: {
          show: true,
          borderColor: "rgba(0, 0, 0, .1)",
          strokeDashArray: 0,
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        chart: {
          fontFamily: '"Nunito Sans",sans-serif',
          foreColor: "#a1aab2",
          toolbar: {
            show: false,
          },
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        colors: ["#4fc3f7", "#2962ff"],
        fill: {
          type: "gradient",
          opacity: ["0.1", "0.1"],
        },
        xaxis: {
          categories: ["1", "2", "3", "4", "5", "6", "7", "8"],
          labels: {
            style: {
              cssClass: "grey--text lighten-2--text fill-color",
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              cssClass: "grey--text lighten-2--text fill-color",
            },
          },
        },
        markers: {
          size: 3,
          strokeColors: "transparent",
        },
        tooltip: {
          x: {
            format: "dd/MM/yy HH:mm",
          },
          theme: "dark",
        },
        legend: {
          show: false,
        },
      },
    }
  }),
  components: {
    VueApexCharts,
    MonthlyProfitList,
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(["getCourses","getRegistrations","getPayments","getWithdrawls"]),
    initialize(){
      this.getCourses().then(res => {
        this.totalCourses = res.length;
      })
      this.getRegistrations().then((res) => {
        this.totalincomes[0].money = _.sum(res.map(r => { if(r.payment_status === 1) { return parseInt(r.fee) } }));
      });
      this.getWithdrawls().then((res) => {
        let disbursed = _.sum(res.map(r => { if(r.st === 1) { return parseInt(r.amount) } }));
        this.totalincomes[1].money = _.sum(res.map(r => { if(r.st === 0) { return parseInt(r.amount) } }));
        this.totalincomes[0].money = this.totalincomes[0].money - disbursed;
        this.totalincomes[2].money = this.totalincomes[0].money - (disbursed + this.totalincomes[1].money);
      });
      this.getPayments().then((res) => {
        this.totalincomes[3].money = _.sum(res.map(p => { if(p.st === 1) { return parseInt(p.amount) } })) * 10/100;
      });
    }
  }
};
</script>