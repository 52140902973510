<template>
  <b-row>
    <!-- --------------------------------------------------------- 
    Sales Summary
    --------------------------------------------------------- -->
    <b-col cols="12">
      <b-card class="mb-4" no-body>
        <SalesSummery></SalesSummery>
      </b-card>
    </b-col>
    <!-- --------------------------------------------------------- 
    Email Campaigns
    --------------------------------------------------------- -->
    <b-col lg="8" cols="12" md="12">
      <b-card class="mb-4">
        <EmailCampaign></EmailCampaign>
      </b-card>
    </b-col>
    <!-- --------------------------------------------------------- 
    Active Visitors
    --------------------------------------------------------- -->
    <b-col lg="4" cols="12" md="12">
      <b-card class="mb-4">
        <ActiveVisitors></ActiveVisitors>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
// -----------------------------------------
// Import Components Here
// -----------------------------------------
import SalesSummery from "../dashboard-components/sales-summery/SalesSummery";
import EmailCampaign from "../dashboard-components/email-campaign/EmailCampaign";
import ActiveVisitors from "../dashboard-components/active-visitors/ActiveVisitors";

// -----------------------------------------
// Export Here
// -----------------------------------------
export default {
  name: "ClassicDashboard",
  data: () => ({
    title: "ClassicDashboard",
    // Month Table
    month1: 0,
    monthoptions1: [
      { text: "Monthly", value: 0 },
      { text: "Daily", value: 1 },
      { text: "Weekly", value: 2 },
      { text: "Yearly", value: 3 },
    ],
  }),
  components: {
    SalesSummery,
    EmailCampaign,
    ActiveVisitors
  },
};
</script>